import React, { useEffect, useState } from 'react'
import { Button } from '@momentum-ui/react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import WebexCCLogo from '../../assets/svgs/webex-cc-logo.svg'
import Integration from '../../components/MyApp/Integration'
import {
  INTEGRATION_ACTION,
  PAGE_VIEWED,
  SERVICE_APP_ACTION,
} from '../../constants/metrics'
import {
  ApplicationTypes,
  IIntegration,
  IServiceApp,
} from '../../interfaces/myAppInterfaces'
import httpService from '../../services/httpService'
import { logError } from '../../services/loggerService'
import MetricsService from '../../services/metricsService'
import { retrieveToken } from '../../state/auth'
import { AppState } from '../../state/store'
import { getBffBaseUrl } from '../../services/configService'
import { getCIUrls } from '../../utils/auth.utils'
import Dialog from '../../components/Dialog'
import { APPLICATION_NOT_SUBMITTED } from '../../constants/applications'
import ServiceApp from '../../components/MyApp/ServiceApp'

interface IEditApplicationProps {
  clientId: string
  clientSecret?: string
  setHasError: Function
  hasBeenSubmitted?: boolean
}
const EditApplication: React.FC<IEditApplicationProps> = ({
  clientId,
  clientSecret,
  setHasError,
  hasBeenSubmitted,
}) => {
  const token = useSelector((state: AppState) => retrieveToken(state))
  const [dataFetched, setDataFetched] = useState<
    IIntegration | IServiceApp | null
  >(null)
  const [showLoadingScreen, setShowLoadingScreen] = useState<boolean>(true)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [applicationName, setApplicationName] = useState<string>('')
  const [hasRecentlyBeenSubmitted, setHasRecentlyBeenSubmitted] = useState<
    boolean
  >(hasBeenSubmitted || false)
  const { t } = useTranslation()
  const metricsService = MetricsService.getInstance()
  const { idBrokerUrl } = getCIUrls()
  const [dialogState, setDialogState] = useState<any>({
    isShowing: false,
    type: '',
  })
  const isServiceApp = dataFetched?.type === ApplicationTypes.ServiceApp

  const renderDialog = (): JSX.Element => {
    const closeDialog = (): void => {
      setDialogState({ isShowing: false })
    }

    return (
      <Dialog
        dialogText={t(
          isServiceApp
            ? 'serviceApp.withdrawSubmissionDialogText'
            : 'integrations.withdrawSubmissionDialogText'
        )}
        onSuccess={(): void => {
          withdrawApplication()
          closeDialog()
        }}
        onClose={closeDialog}
        showDialog={dialogState?.isShowing}
        testIdPrefix="withdraw"
      />
    )
  }

  const renderAppForm = (): JSX.Element => {
    if (isServiceApp) {
      return (
        <ServiceApp
          serviceApp={dataFetched as IServiceApp}
          updateName={updateApplicationName}
        />
      )
    }
    return (
      <Integration
        integration={dataFetched as IIntegration}
        updateName={updateApplicationName}
      />
    )
  }

  const httpConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      idBrokerHost: idBrokerUrl,
    },
  }
  const requestUri = `${getBffBaseUrl()}/v1/applications/${clientId}`

  const withdrawApplication = async (): Promise<void> => {
    setIsSubmitting(true)
    try {
      await httpService.patch(
        `${requestUri}/withdraw`,
        {
          submissionStatus: APPLICATION_NOT_SUBMITTED,
          name: dataFetched?.name,
        },
        httpConfig
      )

      metricsService.track(
        isServiceApp ? SERVICE_APP_ACTION : INTEGRATION_ACTION,
        {
          type: 'Withdraw',
        }
      )

      setHasRecentlyBeenSubmitted(false)
      setDataFetched({
        ...(dataFetched as IIntegration | IServiceApp),
        submissionStatus: APPLICATION_NOT_SUBMITTED,
      })
    } catch (err) {
      setHasError(true)
      logError(`An error has occurred while withdrawing the application ${err}`)
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    metricsService.track(PAGE_VIEWED, {
      pageName: `Edit ${isServiceApp ? 'Service App' : 'Integration'}`,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        const httpResponse = await httpService.get(requestUri, httpConfig)
        setDataFetched({
          ...httpResponse.data,
          ...(clientSecret && { secret: clientSecret }),
        })
        setApplicationName(httpResponse.data.name)
      } catch (err) {
        setHasError(true)
        logError(`An error has occurred while fetching the application ${err}`)
      } finally {
        setShowLoadingScreen(false)
      }
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, token, idBrokerUrl])

  const updateApplicationName = (name: string): void => {
    setApplicationName(name)
  }
  return (
    <section className="edit-application">
      {clientSecret || hasRecentlyBeenSubmitted ? (
        <div className="success-header">
          <h1>{t('header.congratulations')} &#x1f389;</h1>
          {clientSecret ? <h4>{t('myApps.createSuccessSub')}</h4> : null}
          {hasRecentlyBeenSubmitted && (
            <div>
              <p>
                {t(
                  isServiceApp
                    ? 'serviceApp.apphubSubmitSuccess'
                    : 'integrations.apphubSubmitSuccess'
                )}
              </p>
              <p>{t('apphubSubmission.submissionSuccessSupportMessage')}</p>
              <p>{t('apphubSubmission.submissionSuccessEmailMessage')}</p>
            </div>
          )}
        </div>
      ) : null}
      {dataFetched && !showLoadingScreen ? (
        <div className="edit-application-header">
          <h2>{applicationName}</h2>
          {dataFetched?.submissionStatus?.toLowerCase() === 'submitted' && (
            <Button
              className="withdraw-btn"
              ariaLabel={t('apphubSubmission.withdrawSubmission')}
              onClick={(): void => {
                setDialogState({ isShowing: true, type: 'withdraw' })
              }}
              data-testid="withdraw-btn"
              data-cy="withdraw-btn"
              loading={isSubmitting}
            >
              {t('apphubSubmission.withdrawSubmission')}
            </Button>
          )}
        </div>
      ) : null}
      {showLoadingScreen ? (
        <div className="loading-screen">
          <WebexCCLogo className="loading-stuff" />
        </div>
      ) : null}
      {dataFetched && !showLoadingScreen ? renderAppForm() : null}
      {renderDialog()}
    </section>
  )
}

export default EditApplication
